/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import classNames from "classnames";
import CopyButton from "src/components/Common/CopyButton";
import OptionItem from "src/components/Common/OptionItem";
import toastr from "src/components/Common/toastr";
import TruncatedColumn from "src/components/TruncatedColumn/TruncatedColumn";
import { DoorIcon, ReportIcon, VerticalMenuIcon } from "@100mslive/react-icons";
import { Box, Button, Flex, Text, Tooltip } from "@100mslive/roomkit-react";
import { fetchStrapiStarterKits } from "../../actions/CreateAppActions";
import api from "../../api";
import emptySearchStateSVG from "../../assets/images/emptySearch.svg";
import NoSessionsSVG from "../../assets/images/NoSessions.svg";
import Options from "../../components/Common/Options";
import StatusTag from "../../components/Common/StatusTag";
import Table from "../../components/Common/TableNew";
import DeleteDialog from "../../components/Modal/DeleteDialog";
import { AppAnalytics } from "../../helpers/analytics_helper";
import { formattedDate, getLocalZone, secondsToHMS } from "../../utils";
import "toastr/build/toastr.min.css";

const iconStyle = { height: "20px", width: "20px", color: "inherit" };

// eslint-disable-next-line complexity
export default function TableContainer({
  loading,
  sessions,
  activeTab,
  switchTab,
  hasTemplates,
  isSearch = false,
}) {
  const isPast = activeTab === "past";
  const [sessionEndRequests, setSessionEndRequests] = useState({});
  const [endSessionFor, setEndSessionFor] = useState(null);
  const [acknowledged, setAcknowledged] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStrapiStarterKits());
  }, []);

  const zoneAbbr = getLocalZone();

  const pastTableHeaders = [
    "Session ID",
    "Room ID",
    "Peers",
    "Duration",
    `Start Time (${zoneAbbr})`,
    "Recording Status",
    "",
  ];

  const liveTableHeaders = [
    "Session ID",
    "Room ID",
    "Peers",
    `Start Time  (${zoneAbbr})`,
    "Recording Status",
    "",
  ];
  const endRoom = session => {
    const { room_id } = session;

    if (sessionEndRequests[session._id]) {
      return;
    }

    if (!acknowledged) {
      return;
    }

    setSessionEndRequests(prevState => ({
      ...prevState,
      [session._id]: true,
    }));
    setAcknowledged(false);
    setEndSessionFor(null);

    AppAnalytics.track("end_room.clicked", {
      room_id: room_id,
    });
    api
      .service("dashboard")
      .post("room/end-v2", {
        room_id,
        reason: "Ended from dashboard",
      })
      .then(() => {
        AppAnalytics.track("room_end.success", {
          room_id: room_id,
        });
        toastr.success("Session Ending");
      })
      .catch(err => {
        AppAnalytics.track("room_end.failed", {
          room_id: room_id,
          msg: JSON.stringify(err),
        });
        toastr.error("Something went wrong");
      });
  };

  const toggleEndSessionDialog = () => {
    setEndSessionFor(null);
    setAcknowledged(false);
  };
  const getLatestRecordingStatus = recording_logs => {
    return Object.values(
      recording_logs.reduce((acc, val) => {
        acc.push(val.status);
        return acc;
      }, [])
    );
  };

  return (
    <div
      className={classNames("table-container", {
        [activeTab]: activeTab,
        " overflow-auto border-[1px] border-border-default rounded-lg":
          sessions?.length > 0 && hasTemplates,
      })}
    >
      {sessions && (
        <>
          {sessions?.length > 0 && hasTemplates ? (
            <Table
              tableHeaders={isPast ? pastTableHeaders : liveTableHeaders}
              className="table table-borderless mb-0"
              centerFinalColumn
              compact
              noHoverEffect
              hideBottomBorders
            >
              {sessions.map(session => {
                let { startTime } = session.stats;
                const duration = session?.stats?.duration || 0;
                return (
                  <tr key={session._id}>
                    <td className="align-middle">
                      <div className="flex gap-3 items-center whitespace-nowrap">
                        <TruncatedColumn
                          id={session._id}
                          link={`session-details/${session._id}`}
                          linkExist={true}
                        />
                        <CopyButton
                          buttonCSS={{ marginLeft: "$2" }}
                          value={session._id}
                          componentId={`sessions.overview.page.${activeTab}`}
                          btnId="session.id"
                        />
                      </div>
                    </td>
                    <td className="align-middle">
                      <div className="flex gap-3 items-center whitespace-nowrap">
                        <TruncatedColumn
                          id={session.room_id}
                          link={`room-details/${session.room_id}`}
                          linkExist={true}
                        />
                        <CopyButton
                          buttonCSS={{ marginLeft: "$2" }}
                          value={session.room_id}
                          componentId={`sessions.overview.page.${activeTab}`}
                          btnId="room.id"
                        />
                      </div>
                    </td>
                    <td
                      style={{
                        minWidth: "unset",
                        width: "max-content",
                        paddingLeft: "0",
                      }}
                    >
                      <Text
                        variant="sm"
                        css={{
                          color: "$on_surface_medium",
                          textAlign: "center",
                        }}
                      >
                        {session?.peer_count || 0}
                      </Text>
                    </td>
                    {isPast && (
                      <td className="align-middle">
                        <Text
                          variant="sm"
                          css={{
                            color: "$on_surface_medium",
                          }}
                        >
                          {secondsToHMS(duration)}
                        </Text>
                      </td>
                    )}
                    <td className="align-middle">
                      <Text
                        variant="sm"
                        css={{
                          color: "$on_surface_medium",
                        }}
                      >
                        {formattedDate(startTime)}
                      </Text>
                    </td>

                    <td className="px-6">
                      {session?.recording_logs?.length ? (
                        <Flex
                          css={{ px: "$6", alignItems: "center", gap: "$3" }}
                        >
                          <Link
                            to={`session-details/${session._id}?tab=recordingLog`}
                            onClick={() => {
                              AppAnalytics.track("btn.clicked", {
                                btnId: "recording.status",
                                componentId: `sessions.overview.page.${activeTab}`,
                                type: session.recording_logs[0].status,
                                value: session._id,
                              });
                            }}
                          >
                            <StatusTag
                              status={session.recording_logs[0].status}
                            />
                          </Link>
                          {getLatestRecordingStatus(session.recording_logs)
                            ?.length - 1 ? (
                            <Tooltip
                              title={
                                <Box
                                  css={{
                                    px:
                                      getLatestRecordingStatus(
                                        session.recording_logs
                                      )?.length - 1
                                        ? "$6"
                                        : 0,
                                  }}
                                >
                                  {getLatestRecordingStatus(
                                    session.recording_logs
                                  )
                                    .filter((log, index) => index !== 0)
                                    .map((value, index) => (
                                      <div key={index} className="my-2 pr-1">
                                        <StatusTag status={value} />
                                      </div>
                                    ))}
                                </Box>
                              }
                            >
                              <Text
                                variant="xs"
                                css={{
                                  fontWeight: "$semiBold",
                                  color: "$on_surface_medium",
                                  backgroundColor: "$surface_brighter",
                                  p: "$4",
                                  borderRadius: "$0",
                                }}
                              >
                                {`+${
                                  getLatestRecordingStatus(
                                    session.recording_logs
                                  ).length - 1
                                }`}
                              </Text>
                            </Tooltip>
                          ) : null}
                        </Flex>
                      ) : (
                        <Flex align="center" css={{ pr: "28px", pl: "$6" }}>
                          <Text>-</Text>
                        </Flex>
                      )}
                    </td>
                    {isPast ? (
                      <td
                        style={{
                          minWidth: "unset",
                          width: "max-content",
                          padding: "16px 12px",
                        }}
                      >
                        <Options
                          Icon={<VerticalMenuIcon />}
                          content={[
                            {
                              data: (
                                <OptionItem
                                  link={`/events-inspector?id=${session._id}`}
                                  icon={<ReportIcon style={iconStyle} />}
                                  title="Inspect Session"
                                />
                              ),
                              func: () => {
                                AppAnalytics.track("btn.clicked", {
                                  btnId: "inspect.session",
                                  componentId: `sessions.overview.page.${activeTab}`,
                                  value: session._id,
                                });
                              },
                            },
                            {
                              data: (
                                <OptionItem
                                  link={`/session-details/${session._id}`}
                                  icon={<DoorIcon style={iconStyle} />}
                                  title="View Session Details"
                                />
                              ),
                              func: () => {
                                AppAnalytics.track("btn.clicked", {
                                  btnId: "view.session.details",
                                  componentId: `sessions.overview.page.${activeTab}`,
                                  value: session._id,
                                });
                              },
                            },
                          ]}
                        />
                      </td>
                    ) : null}

                    {isPast ? null : (
                      <td>
                        <Button
                          variant="danger"
                          outlined
                          disabled={sessionEndRequests[session._id]}
                          onClick={() => {
                            setEndSessionFor(session);
                            AppAnalytics.track("btn.clicked", {
                              btnId: "end.session",
                              componentId: `sessions.overview.page.${activeTab}`,
                              value: session.room_id,
                            });
                          }}
                        >
                          <Text
                            variant="sm"
                            css={{
                              fontWeight: "$semiBold",
                              color: "$on_surface_high",
                            }}
                          >
                            End session
                          </Text>
                        </Button>
                      </td>
                    )}
                  </tr>
                );
              })}
            </Table>
          ) : isSearch ? (
            !loading &&
            !sessions.length && (
              <div className="flex-col flex min-w-full min-h-full justify-center items-center ">
                <img
                  src={emptySearchStateSVG}
                  alt="empty_search_state_sessions_png"
                />
                <Text variant="h4" css={{ c: "$on_surface_high", mt: "$12" }}>
                  No results found
                </Text>
                <Text
                  variant="body1"
                  css={{
                    c: "$on_surface_medium",
                    mt: "$4",
                    fontWeight: "$regular",
                  }}
                >
                  Try adjusting your search to find what you’re looking for.
                </Text>
              </div>
            )
          ) : (
            !loading &&
            !sessions.length && (
              <div className="flex-col flex min-w-full min-h-full justify-center items-center ">
                <img src={NoSessionsSVG} alt="no_sessions_svg" />
                <Text variant="h4" css={{ c: "$on_surface_high", mt: "$12" }}>
                  {!hasTemplates
                    ? "No sessions yet"
                    : `No ${activeTab} sessions`}
                </Text>
                <Text
                  variant="body1"
                  css={{
                    c: "$on_surface_medium",
                    mt: "$4",
                    fontWeight: "$regular",
                  }}
                >
                  {!hasTemplates
                    ? "Go to Rooms and join or create a room to start a session."
                    : `There are currently no ${
                        activeTab === "live" ? "active" : "past"
                      }
                sessions`}
                </Text>
                {!hasTemplates ? (
                  <a href="/rooms">
                    <Button variant="primary" css={{ mt: "$12" }}>
                      <Text variant="body1">
                        <DoorIcon /> View Rooms
                      </Text>
                    </Button>
                  </a>
                ) : (
                  <Button
                    variant="primary"
                    onClick={() => {
                      switchTab();
                      window.history.replaceState(null, null, "?tab=past");
                    }}
                    css={{ mt: "$12" }}
                  >
                    <Text variant="body1">
                      View {activeTab === "live" ? "Past" : "Live"} Sessions
                    </Text>
                  </Button>
                )}
              </div>
            )
          )}
        </>
      )}
      <DeleteDialog
        title="End Session"
        subtitle="Are you sure you want to end this session? This action is irreversible."
        data={false}
        CheckBoxSectionCSS={{ mt: "$10" }}
        toggleCheckBox={() => setAcknowledged(prev => !prev)}
        CheckboxText={() => (
          <Text
            variant="body2"
            css={{ c: "$on_surface_high", fontWeight: "$semiBold" }}
          >
            I understand and would like to proceed.
          </Text>
        )}
        open={!!endSessionFor}
        loading={false}
        disableButton={!acknowledged}
        deleteFunction={() => endRoom(endSessionFor)}
        compactButtons
        toggleDeleteDialog={toggleEndSessionDialog}
        cancelButtonText="Cancel"
      />
    </div>
  );
}
